import { useEffect } from "react";
import Head from "next/head";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import path from "path";
import { SWRConfig } from "swr";

import { LandingPageDocument, RenderSection } from "@tm/ui/ui-container";
import { RootContainer } from "../style/allRoutes.style";
import {
  domainRedirect,
  fetcher,
  getDeviceType,
  getHost,
  template,
} from "@tm/utility";
import { pushEvent } from "@tm/utility/analytics";
import { logger } from "../src/winstonLogger";
import { DEVICE_TYPE } from "@tm/types";
import { serverRuntimeConfig } from "@tm/utility";

const { cmsBaseUrl } = serverRuntimeConfig();

// Magic import, https://github.com/nrwl/nx/issues/9017#issuecomment-1140066503
path.resolve("./next.config.js");

const Index = ({ cmsData }) => {
  const { pageViewEvent } = cmsData?.meta || {};
  const components = cmsData?.[0]?.jsons;
  const { className } = cmsData?.[0]?.config || {};
  const title =
    cmsData?.[0]?.seo?.[0]?.title ||
    "TripMoney - Buy Forex Card & Travel Insurance Online | Forex India";

  useEffect(() => {
    if (pageViewEvent) {
      pushEvent(pageViewEvent);
    }
  }, [pageViewEvent]);

  useEffect(() => {
    if (className)
      document.querySelector("body").classList.add(className.split(" "));
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <SWRConfig value={{ fetcher, revalidateOnFocus: false }}>
        <LandingPageDocument
          metaTags={cmsData?.[0]?.seo?.[0]?.meta_tags || []}
          preloadedImages={cmsData?.[0]?.config?.preloadedImages || []}
        >
          <RootContainer id="root_container" className={className}>
            {components?.map((component: any, index: number) => {
              return (
                <RenderSection
                  key={index}
                  component={component}
                  pageViewEvent={pageViewEvent}
                  className={className}
                />
              );
            })}
          </RootContainer>
        </LandingPageDocument>
      </SWRConfig>
    </>
  );
};

function getPageUrl(url: string, userAgent: string) {
  if (url !== "/") {
    url = `/revamp${url}`;
  } else {
    url = `/revamp`;
  }
  const deviceType = getDeviceType(userAgent);
  return url + (deviceType !== DEVICE_TYPE.DESKTOP ? `/${deviceType}` : "");
}
function updateSectionOrders(data: any) {
  const {
    config: { sectionOrder },
    jsons,
  } = data[0];
  let orderedJsons: any;
  if (sectionOrder && sectionOrder.length > 0) {
    orderedJsons = sectionOrder.map((sectionName: string) => {
      return (
        jsons.find(
          (json: any) => json.type.toLowerCase() === sectionName.toLowerCase()
        ) || {}
      );
    });
  }
  return orderedJsons;
}

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  domainRedirect(context);
  let url = context.req.url;
  if (process.env.NODE_ENV === "development") {
    const userAgent = context.req.headers["user-agent"];
    url = getPageUrl(url, userAgent);
  }

  const host = getHost(context?.req?.headers);
  const pathName = new URL(url, `https://${host.toString()}`).pathname;

  logger.info(
    `URL: ${url}, pathName: ${pathName}, status: ${context.res.statusCode} [all_routes]`
  );

  let data = [];
  try {
    logger.info(`${cmsBaseUrl}/pages?url=${pathName}`);
    const response = await fetch(`${cmsBaseUrl}/pages?url=${pathName}`);
    if (response.status === 200) {
      data = await response.json();
      if (data?.length === 0) {
        return {
          notFound: true,
        };
      }
      const orderedJsons = updateSectionOrders(data);
      if (orderedJsons) {
        data[0].jsons = orderedJsons;
      }
    }

    data = template(data);
    return {
      props: {
        cmsData: data,
        isMobile: pathName.endsWith(DEVICE_TYPE.MOBILE),
        isTablet: pathName.endsWith(DEVICE_TYPE.TABLET),
      },
    };
  } catch (e) {
    logger.error(`${url} ${e.message} [all_routes]`);
    throw new Error(e.message);
  }
};

export default Index;
