import styled from "styled-components";

export const RootContainer = styled.div`
  &.home {
    overflow-y: scroll;
    height: 100vh;
  }
`;

export const LandingInitial = styled.div`
  width: 100vw;
  height: 100vh;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 41;

  & .myProgress {
    height: 5px;
    background: #000;
    width: 100%;
    position: fixed;
    top: 0;
  }

  & .myBar {
    height: 5px;
    background: #d88585;
    border-radius: 0 8px 8px 0px;
    animation: progress 4s cubic-bezier(0, 0.76, 0.99, 0.26);
  }

  @keyframes progress {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;

export const LandingLoading = styled.div`
  width: 326px;
  animation: fadeIn 4s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 100%;
    }
  }
`;
