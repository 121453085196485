
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[[...all_routes]]",
      function () {
        return require("private-next-pages/[[...all_routes]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[[...all_routes]]"])
      });
    }
  